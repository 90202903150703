<!--
 * @FileDescription:网盟-用户界面
 * @Author: 蔡林泽
 * @Date: 2022/3/8
 * @LastModifiedTime: 2022/3/8
 -->
<template>
  <div class="main">
    <h1>Customer</h1>
    <div class="data f">
      <div>
        <p>Total Orders</p>
        <span>{{ listData.order_num }}</span>
      </div>
      <div>
        <p>Total Order Value</p>
        <span>US$ {{ listData.order_amount }}</span>
      </div>
      <div>
        <p>Commision</p>
        <span>US$ {{ listData.commision }}</span>
      </div>
    </div>
    <div class="list-filter bg-white f">
      <!-- 搜索 -->
      <el-input v-model="listForm.customer_name" clearable placeholder="search..." class="input-with-select" style="width:270px;" maxlength="100" @change="resetPage">
        <el-button slot="append" icon="el-icon-search" />
      </el-input>
      <!-- <el-select v-model="listForm.status" clearable placeholder="Please choose..." style="width:200px;" @change="resetPage">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
      </el-select> -->
    </div>
    <TableContainer :current="listForm.page" :total="total" :height="($store.state.common.clientHeight-340)+'px'" @change="pageChange">
      <el-table v-loading="loading" :data="list" :height="($store.state.common.clientHeight-397)+'px'">
        <el-table-column prop="customer_name" label="Customer Name" min-width="200" />
        <el-table-column prop="order_num" label="Total Orders" min-width="200" />
        <el-table-column prop="order_amount" label="Total Order Value" min-width="200" />
        <el-table-column prop="commision" label="Commision" min-width="200" />
      </el-table>
    </TableContainer>

  </div>
</template>

<script>
import API from '@/api/index'
import list from '@/mixin/list'

export default {
  mixins: [list],
  data() {
    return {
      tableData: [],
      loading: false,
      listData: ''
    }
  },
  created() {
    this.getList()
    this.getUserStatistics()
  },
  methods: {
    getList() {
      let parmas = Object.assign({}, this.listForm)
      this.loading = true
      API.getListUsers(parmas).then(res => {
        this.list = res.data.list || []
        this.total = res.data.pages.total
      }).finally(() => {
        this.loading = false
      })
    },
    Jump(row) {
      if (row.state === 'End') return this.$message.warning('The current share link is invalid')
      window.open(row.url)
    },
    handleEdit(row) {
      this.$refs.details.showDialog(row)
    },
    getUserStatistics() {
      API.getUserStatistics().then(res => {
        this.listData = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  .data {
    width: 100%;
    height: 120px;
    margin: 10px 0;
    background: #ffffff;
    div {
      // margin: 40px;
      padding-top: 20px;
      margin: 0 110px;
      p {
        line-height: 43px;
        font-weight: 500;
        font-size: 16px;
        color: #747a87;
      }
      span {
        font-weight: 500;
        font-size: 26px;
        color: #000000;
      }
    }
  }
}
</style>
